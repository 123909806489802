import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ApiError, AssetCartDTO, AssetCartItemDTO, AssetCartItemEditDTO, AssetSearchResultDTO } from "../openapi/requests";
import { useAssetCartServiceDeleteAssetCart, useAssetCartServiceDeleteAssetCartItems, useAssetCartServiceGetAssetCart, useAssetCartServicePostAssetCart, useAssetCartServicePostAssetCartItems, useAssetCartServicePutAssetCart } from "../openapi/queries";
import { DialogContext } from "./DialogContext";

export interface AssetCartContextState {
    addToCart: (asset: AssetSearchResultDTO) => Promise<any>;
    removeFromCart: (item: AssetCartItemDTO) => void;
    currentCart?: AssetCartDTO;
    carts: AssetCartDTO[];
    createNewCart: (name?: string) => Promise<any>;
    renameCart: (cart: AssetCartDTO, name: string) => Promise<any>;
    deleteCart: (cart: AssetCartDTO) => Promise<any>;
    setCurrentCart: (cart: AssetCartDTO) => void;
    isLoading: boolean;
}

const contextDefaultValues: AssetCartContextState = {
    addToCart: () => Promise.resolve(),
    createNewCart: () => Promise.resolve(),
    renameCart: () => Promise.resolve(),
    currentCart: undefined,
    deleteCart: () => Promise.resolve(),
    carts: [],
    removeFromCart: () => {},
    setCurrentCart: () => {},
    isLoading: false,
}
    
export const AssetCartContext = createContext<AssetCartContextState>(
    contextDefaultValues
);

export const AssetCartProvider: FC<{ children: React.ReactNode }> = ({children}) => {
    const [currentCart, setCurrentCart] = useState<AssetCartDTO | undefined>();
    const { message} = useContext(DialogContext);

    const {data:cartsResponse, isLoading: isCartsLoading, refetch: refetchCarts} = useAssetCartServiceGetAssetCart({});
    const {mutateAsync: deleteAssetCartAsync, isPending: isDeletePending} = useAssetCartServiceDeleteAssetCart();
    const {mutateAsync: createCartAsync, isPending: isCreatePending} = useAssetCartServicePostAssetCart();
    const {mutateAsync: editCartAsync, isPending: isEditPending} = useAssetCartServicePutAssetCart();
    const {mutateAsync: addToCartAsync, isPending: isAddPending} = useAssetCartServicePostAssetCartItems();
    const {mutateAsync: deleteFromCartAsync, isPending: isRemovePending} = useAssetCartServiceDeleteAssetCartItems();

    const isLoading = isCartsLoading || isDeletePending || isCreatePending || isAddPending || isRemovePending || isEditPending;

    const carts = useMemo(() => {
        return cartsResponse?.value ?? [];
    }, [cartsResponse?.value]);

    const deleteCart = useCallback(async (cart: AssetCartDTO) => {
        await deleteAssetCartAsync({ key: cart.id });
        if (currentCart?.id === cart.id) {
            setCurrentCart(undefined);
        }
        refetchCarts();
    }, [currentCart, deleteAssetCartAsync, refetchCarts]);

    const createNewCart = useCallback(async (name?: string) => {
        var cart = await createCartAsync({ requestBody: { name: name ?? "" } });
        setCurrentCart(cart);
        refetchCarts();
    }, [createCartAsync, refetchCarts]);

    const renameCart = useCallback(async (cart: AssetCartDTO, name: string) => {
        await editCartAsync({ key: cart.id, requestBody: { name: name } });
        refetchCarts();
    }, [editCartAsync, refetchCarts]);

    const addToCart = useCallback(async (asset: AssetSearchResultDTO) => {
        let cart = currentCart;
        if(!cart){
            cart = await createCartAsync({ requestBody: { name: "Unnamed cart" } });
        }
        let body: AssetCartItemEditDTO  = { assetId: asset.assetId };
        
        if(asset.assetType === "workflowassets") {
            body = { wfAssetId: asset.id };
        }
        if(asset.assetType === "orderline") {
            body = { orderlineId: asset.id };
        }

        await addToCartAsync({ key: cart.id, requestBody:  body}).catch(reason => {
            let apiError = reason as ApiError;
            let body = apiError.body as {error: {message: string}};

            if(body && body.error && body.error.message === "Item already added"){
                message("Item already in basket", "");
            }
        })
        refetchCarts();
    }, [addToCartAsync, createCartAsync, currentCart, message, refetchCarts]);

    const removeFromCart = useCallback(async (item: AssetCartItemDTO) => {
        let cart = carts.find(e => e.items.some(e => e.key === item.key));
        if(cart){
            await deleteFromCartAsync({ key: cart.id, id: item.key });
            refetchCarts();
        }else{
            return Promise.reject("Cant find cart");
        }
    }, [carts, deleteFromCartAsync, refetchCarts]);

    useEffect(() => {
        let cart = carts.find(e => e.id === currentCart?.id);
        if(cart === undefined){
            cart = carts[0];
        }
        setCurrentCart(cart);
    }, [carts, currentCart]);

    return (
        <AssetCartContext.Provider 
            value={{
                carts,
                currentCart,
                setCurrentCart,
                isLoading,
                deleteCart,
                createNewCart,
                renameCart,
                addToCart,
                removeFromCart,
            }}
            >  
           {children}
        </AssetCartContext.Provider>
    )
}