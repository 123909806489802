import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import ModelDetails from '../designer/ModelDetails';
import TemplateDetails from '../designer/TemplateDetails';
import AssetFilter from '../layout/AssetFilter';
import AssetList from '../layout/AssetList';
import Layout from '../layout/Layout';
import { ConfigContext } from '../../contexts/ConfigContext';
import { GenerateODataFilter } from '../../helpers/odataFunctions';
import { useSearchAssetServiceGetSearchAsset } from '../../openapi/queries';
import { AssetSearchResultDTO } from '../../openapi/requests';
import { Filter } from '../../types/types';
import ModelpackDetails from '../designer/ModelpackDetails';
import MaterialDetails from '../designer/MaterialDetails';
import ModifierDetails from '../designer/ModifierDetails';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { ReactComponent as Search2Icon } from '../../assets/icons/search2.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as CartIcon } from '../../assets/icons/cart.svg';
import './AssetSearch.scss';
import BottomDrawer from '../layout/BottomDrawer';
import { BASE_PATH } from '../..';
import { AnimatePresence, motion } from 'framer-motion';
import { AssetCartContext } from '../../contexts/AssetCartContext';
import AssetCart from './AssetCart';
import WorkflowAssetDetails from '../designer/WorkflowAssetDetails';
import { DialogContext } from '../../contexts/DialogContext';
import { ProjectDetail } from '../project/ProjectDetail';
import Overlay from '../layout/Overlay';
import OrderlineDetails from '../designer/OrderlineDetails';
import { ReactComponent as Spin360 } from "../../assets/icons/spin360.svg";
import { ReactComponent as Insitu } from "../../assets/icons/in-situ.svg";
import { ReactComponent as Cutout } from "../../assets/icons/cutout.svg";
import { ReactComponent as Animation } from "../../assets/icons/animation.svg";
import { OrderlineFileType } from '../../models/enums';

interface Props {
    selection: AssetSearchResultDTO[];
    onAssetSelected: (asset: AssetSearchResultDTO) => void;
    onSearchProjectsAll: () => void;
    onSearchProjectsAny: () => void;
}

const assetTypeOptions = [{value: 'model', label: 'Model'}, {value: 'material', label: 'Material'}, {value: 'scene', label: 'Scene'}, {value: 'modifier', label: 'Modifier'}, {value: 'workflowassets', label: 'Workflow'}, {value: 'project', label: 'Projects'}, {value: 'orderline', label: 'Files'}];

const AssetSearch: React.FC<Props> = ({ onAssetSelected, selection, onSearchProjectsAll, onSearchProjectsAny }) => {
    const {addToCart} = useContext(AssetCartContext);
    const { odataAssetFilters } = useContext(ConfigContext);
    const { error } = useContext(DialogContext);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [query, setQuery] = useState('');
    const [value] = useDebounce(query, 250);

    const [selectedAsset, setSelectedAsset] = useState<AssetSearchResultDTO | undefined>(undefined);
    const [showTemplateDetails, setShowTemplateDetails] = useState(false);
    const [showModelDetails, setShowModelDetails] = useState(false);
    const [showModelpackDetails, setShowModelpackDetails] = useState(false);
    const [showMaterialDetails, setShowMaterialDetails] = useState(false);
    const [showModifierDetails, setShowModifierDetails] = useState(false);
    const [showWorkflowDetails, setShowWorkflowDetails] = useState(false);
    const [showProjectDetails, setShowProjectDetails] = useState(false);
    const [showOrderlineDetails, setShowOrderlineDetails] = useState(false);

    const unFilteredSearchResult = useSearchAssetServiceGetSearchAsset({ q: value, filter: GenerateODataFilter([...odataAssetFilters]) }, undefined, {enabled: !!value});
    const searchResult = useSearchAssetServiceGetSearchAsset({ q: value, filter: GenerateODataFilter([...odataAssetFilters, ...filters]) }, undefined, {enabled: !!value});

    const brands = useMemo(() => {
        let values = unFilteredSearchResult?.data?.value.map(e => e.metadata.find(m => m.name.toLowerCase() === "brand")?.value ?? "").filter(e => e !== "");
        values = Array.from(new Set(values));
        return values.map(e => ({ value: e, label: e }));
    }, [unFilteredSearchResult]);

    const ranges = useMemo(() => {
        let values = unFilteredSearchResult?.data?.value.map(e => e.metadata.find(m => m.name.toLowerCase() === "product_range")?.value ?? "").filter(e => e !== "");
        values = Array.from(new Set(values));
        return values.map(e => ({ value: e, label: e }));
    }, [unFilteredSearchResult]);

    const productTypes = useMemo(() => {
        let values = unFilteredSearchResult?.data?.value.map(e => e.metadata.find(m => m.name.toLowerCase() === "product_type_primary")?.value ?? "").filter(e => e !== "");
        values = Array.from(new Set(values));
        return values.map(e => ({ value: e, label: e }));
    }, [unFilteredSearchResult]);

    const categories = useMemo(() => {
        let values = unFilteredSearchResult?.data?.value.map(e => e.metadata.find(m => m.name.toLowerCase() === "category")?.value ?? "").filter(e => e !== "");
        values = Array.from(new Set(values));
        return values.map(e => ({ value: e, label: e }));
    }, [unFilteredSearchResult]);

    const types = useMemo(() => {
        let values = unFilteredSearchResult?.data?.value.map(e => e.assetType).filter(e => e !== "");
        return assetTypeOptions.filter(e => values?.includes(e.value));
    }, [unFilteredSearchResult]);

    const handleClick = useCallback((item: AssetSearchResultDTO) => {
        setSelectedAsset(item);

        if (item.assetType === "scene") {
            setShowTemplateDetails(true);
        }
        if (item.assetType === "model") {
            setShowModelDetails(true);
        }
        if (item.assetType === "modelpack") {
            setShowModelpackDetails(true);
        }
        if (item.assetType === "material") {
            setShowMaterialDetails(true);
        }
        if (item.assetType === "modifier") {
            setShowModifierDetails(true);
        }
        if (item.assetType === "workflowassets") {
            setShowWorkflowDetails(true);
        }
        if (item.assetType === "project") {
            setShowProjectDetails(true);
        }
        if (item.assetType === "orderline") {
            setShowOrderlineDetails(true);
        }
    }, []);

    const handleAssetSelect = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, asset: AssetSearchResultDTO) => {
        e.preventDefault();
        e.stopPropagation();
        onAssetSelected(asset);
    }, [onAssetSelected]);

    const handleAssetAdd = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, asset: AssetSearchResultDTO) => {
        e.preventDefault();
        e.stopPropagation();
        addToCart(asset).catch(reason => {
            error(undefined, reason)
        })
    }, [addToCart, error]);

    const renderOverlay = useCallback((asset: AssetSearchResultDTO) => {

        let icon: React.ReactElement | null = null;
        let label = asset.assetType;

        switch(asset.assetType){
            case 'orderline':
                label = 'file';
                break;
            case 'workflowassets':
                label = 'workflow';
                break;
        }

        if(asset.assetType === "orderline"){
            if(asset.fileType === OrderlineFileType.Spin) {
                icon = <Spin360 />;
            }
            if(asset.fileType === OrderlineFileType.Cutout) {
                icon = <Cutout />;
            }
            if(asset.fileType === OrderlineFileType.Still) {
                icon = <Insitu />;
            }
            if(asset.fileType === OrderlineFileType.Animation) {
                icon = <Animation />;
            }
            if(asset.fileType === OrderlineFileType.Clip) {
                icon = <Animation />;
            }
        }

        return (
            <>
                <div className='buttons'>
                    <button><InfoIcon /></button>
                    {(asset.assetType === "model" || asset.assetType === "scene" || asset.assetType === "modelpack" || asset.assetType === "material" || asset.assetType === "modifier") && <button onClick={e => handleAssetSelect(e, asset)}><Search2Icon /></button>}
                    <button onClick={e => handleAssetAdd(e, asset)}><CartIcon /></button>
                </div>
                <span className='assettype tag'>
                    {label}
                    {icon}
                </span>
            </>
        )
    }, [handleAssetAdd, handleAssetSelect]);

    return (<Layout className='asset-search'>
        <header>
            <div>
                <div className='search-field'>
                    <SearchIcon />
                    <input type='search' value={query} onChange={e => setQuery(e.currentTarget.value)} placeholder='Type to search...' />
                </div>

                <AssetFilter filters={filters} onChange={e => setFilters(e)} disabled={!(unFilteredSearchResult?.data?.value.length > 0)} items={[]} properties={[
                    { name: 'Brand', property: 'metadata.brand', type: 'select', itemsOverride: brands },
                    { name: 'Range', property: 'metadata.product_range', type: 'select', itemsOverride: ranges },
                    { name: 'Product Type', property: 'metadata.product_type_primary', type: 'select', itemsOverride: productTypes },
                    { name: 'Category', property: 'metadata.category', type: 'select', itemsOverride: categories },
                    { name: 'Asset Type', property: 'assetType', type: 'select', itemsOverride: types },
                ]} />
            </div>
        </header>
        <div className='search-page-container'>
            <AssetList assets={searchResult?.data?.value ?? []} displayMode='medium' onItemClick={handleClick} renderOverlay={renderOverlay} />
        </div>

        <AssetCart />

        <BottomDrawer open={selection.length > 0} size={220} modal={false} className='selection'>
            <div className='assets'>
                <AnimatePresence>
                    {selection.map(asset => <motion.div 
                        key={asset.name} 
                        initial={{ opacity: 0 }} 
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }} 
                        className='asset'>
                            <img src={BASE_PATH + asset.thumbnail} alt={asset.title} />
                            <p>{asset.title}</p>
                            <div className='buttons'>
                                <button onClick={e => handleAssetSelect(e, asset)}><DeleteIcon /></button>
                            </div>
                    </motion.div>)}
                </AnimatePresence>
            </div>
            <div className='submit'>
                <h1>Find projects</h1>
                <button onClick={onSearchProjectsAll}>Using all selected assets</button>
                <button onClick={onSearchProjectsAny}>Using any of the selected assets</button>
            </div>
        </BottomDrawer>

        <TemplateDetails open={showTemplateDetails} onClose={() => setShowTemplateDetails(false)} templateId={selectedAsset?.id} />
        <ModelDetails open={showModelDetails} onClose={() => setShowModelDetails(false)} modelId={selectedAsset?.id} />
        <ModelpackDetails open={showModelpackDetails} onClose={() => setShowModelpackDetails(false)} modelpackId={selectedAsset?.id} />
        <MaterialDetails open={showMaterialDetails} onClose={() => setShowMaterialDetails(false)} materialId={selectedAsset?.id} />
        <ModifierDetails open={showModifierDetails} onClose={() => setShowModifierDetails(false)} modifierId={selectedAsset?.id} />
        <WorkflowAssetDetails open={showWorkflowDetails} onClose={() => setShowWorkflowDetails(false)} asset={selectedAsset} />
        <OrderlineDetails open={showOrderlineDetails} onClose={() => setShowOrderlineDetails(false)} orderId={selectedAsset?.orderId} orderlineId={selectedAsset?.id} />
        <Overlay open={showProjectDetails} onClose={() => setShowProjectDetails(false)} className='asset-details'>
            <ProjectDetail projectId={showProjectDetails ? selectedAsset?.id : undefined} />
        </Overlay>
    </Layout>)
};

export default AssetSearch;