import React, { useCallback, useContext, useEffect, useState } from 'react';
import 'chart.js/auto';
import { Spin } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import StatisticsChart from './StatisticsChart';
import StatisticsFilter, { Filter } from '../../statistics/StatisticsFilter';
import { subMonths } from 'date-fns';
import "./StatisticsView.scss";
import { ChartDTO, ModelService, ProjectType, RenderType, SceneService, StatisticsService, TemplateService } from '../../../openapi/requests';
import { ConfigContext } from '../../../contexts/ConfigContext';


const StatisticsView: React.FC = () => {
    const { currentClient} = useContext(ConfigContext)
    const [charts, setCharts] = useState<ChartDTO[]>([]);
    const [loading, setLoading] = useState(true);
    const [scenes, setScenes] = useState<DefaultOptionType[]>([]);
    const [models, setModels] = useState<DefaultOptionType[]>([]);
    const [filters, setFilters] = useState<Filter[]>([]);

    const reload = useCallback(() => {
        setLoading(true);

        let dateValues = filters.find(x => x.property === "date");
        let startDate = subMonths(new Date(), 3);
        let endDate = new Date();

        if (dateValues && dateValues.values.length > 0) {
            startDate = new Date(parseInt(dateValues.values[0]));
            if (dateValues.values[1])
                endDate = new Date(parseInt(dateValues.values[1]));
        }

        let intervalValue = filters.find(x => x.property === "interval");
        let interval = "week";
        if (intervalValue && intervalValue.values.length > 0) {
            interval = intervalValue.values[0].value;
        }

        let sceneValue = filters.find(x => x.property === "scene");
        let scene = undefined;
        if (sceneValue && sceneValue.values.length > 0) {
            scene = (sceneValue.values[0].value !== "") ? parseInt(sceneValue.values[0].value) : undefined;
        }

        let renderTypeValue = filters.find(x => x.property === "rendertype");
        let renderType: RenderType | undefined = undefined;
        if (renderTypeValue && renderTypeValue.values.length > 0) {
            renderType = renderTypeValue.values[0].value;
        }

        let projectTypeValue = filters.find(x => x.property === "projecttype");
        let projectType: ProjectType | undefined = undefined;
        if (projectTypeValue && projectTypeValue.values.length > 0) {
            projectType = projectTypeValue.values[0].value;
        }

        let modelValue = filters.find(x => x.property === "model");
        let model = undefined;
        if (modelValue && modelValue.values.length > 0) {
            model = (modelValue.values[0].value !== "") ? modelValue.values[0].value : undefined;
        }

        var statistics = StatisticsService.getStatistics(startDate.toISOString(), endDate.toISOString(), model, undefined, undefined, interval, scene, renderType, projectType);
        var scenes = SceneService.getScene(undefined, undefined, undefined, `IsEnabled eq true`, undefined, undefined, undefined);
        var templates = TemplateService.getTemplate(undefined, undefined, undefined, undefined,undefined, 'scene', undefined);
        var models = ModelService.getModel();

        Promise.all([statistics, scenes, templates, models]).then((resp) => {
            setCharts(resp[0].value)

            var sceneOptionsData = resp[1].value;
            var templateOptionsData = resp[2].value;
            var modelOptionsData = resp[3].value;

            if (projectType !== undefined) {
                var productTypeValue = (projectType?.toString() === "cutout") ? "cutout" : "generic_3d_scene";

                var sceneIds = templateOptionsData.filter(x => x.editorModule === productTypeValue).map(x => x.sceneId);
                sceneOptionsData = sceneOptionsData.filter(x => sceneIds.includes(x.id));
            }

            const sceneOptions = sceneOptionsData.map(x => ({ label: x.title, value: x.id.toString() }));
            sceneOptions.unshift({ label: "All", value: "" });
            setScenes(sceneOptions);

            const modelOptions = modelOptionsData.map(x => ({ label: x.title, value: x.name.toString() }));
            modelOptions.unshift({ label: "-", value: "" });
            setModels(modelOptions);

            setLoading(false);
        });
    }, [filters]);

    useEffect(() => {
        reload();
    }, [reload]);

    const dateIntervalOptions: DefaultOptionType[] = [
        { label: "Week", value: "week" },
        { label: "Month", value: "month" },
        { label: "Quarter", value: "quarter" },
        { label: "Year", value: "year" }
    ];

    const renderTypeOptions = [
        { label: "Preview", value: "preview" },
        { label: "Final", value: "final" },
    ]

    const projectTypeOptions = [
        { label: "In-situ", value: "insitu" },
        { label: "Cutout", value: "cutout" },
    ]

    return (
        <>
            <StatisticsFilter isOpen={true} filters={filters} onChange={e => setFilters(e)} properties={[
                { name: 'Date', property: 'date', type: 'daterange', items: scenes },
                { name: 'Interval', property: 'interval', type: 'select', items: dateIntervalOptions },
                { name: 'Project Type', property: 'projecttype', type: 'select', items: projectTypeOptions },
                { name: 'Scene', property: 'scene', type: 'select', items: scenes },
                { name: 'Render Type', property: 'rendertype', type: 'select', items: renderTypeOptions },
                { name: 'Model', property: 'model', type: 'select', items: models }
            ]} />

            <Spin spinning={loading}>
                <div className='statistics-charts'>
                    {charts.map(chart => (
                        <>
                            {chart.data.map(chartData => (
                                <div>
                                    <StatisticsChart labels={chart.labels} chartData={chartData} color="#4096ff" />
                                </div>
                            ))}
                        </>
                    ))}
                </div>
            </Spin>
        </>
    )
}

export default StatisticsView;