import Drawer from 'react-modern-drawer';
import "./Menu.scss";
import { Link } from 'react-router-dom';
import { useCallback, useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { ConfigContext } from '../../../contexts/ConfigContext';


export const Menu: React.FC<{ isOpen: boolean, onClose: () => void }> = ({ isOpen, onClose }) => {

    const { username, logOut, hasPermission } = useContext(AuthContext);
    const { currentClient } = useContext(ConfigContext)

    const onLogout = useCallback(() => {
        logOut();
        onClose();
    }, [logOut, onClose]);

    const handleMenuClick = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <Drawer className="menu" open={isOpen} direction='top' size={900} enableOverlay={false}>
            <header>
                <Link to={'/create'}><span className='logo'>C✦D</span></Link>
                <button onClick={() => onClose()}>Close</button>
            </header>
            <div className="menu-body">
                <ul onClick={handleMenuClick}>
                    <li><Link to={'/create'}>Home</Link></li>
                    {(hasPermission("CreateOrders") && (hasPermission("FinalRender") || hasPermission("PriorityRender") || hasPermission("PreviewRender"))) && <li><Link to={'/create/new'}>Create In-Situ</Link></li>}
                    {(hasPermission("CreateOrders") && (hasPermission("FinalRender") || hasPermission("PriorityRender"))) && <li><Link to={'/create/cutouts'}>Create Cut-out</Link></li>}
                    <li><Link to={'/create/projects'}>Project overview</Link></li>
                    {hasPermission("ViewStatistics") && <li><Link to={'/create/statistics'}>Statistics</Link></li>}
                    {hasPermission("Search") && <li><Link to={'/create/search'}>Search</Link></li>}
                </ul>
            </div>
            <footer>
                <div className="menu-brand">{currentClient.name}</div>
                <div className="menu-user">
                    <span>Welcome</span>
                    <span>{username}</span>
                </div>
                <div className="menu-actions">
                    <button onClick={() => onLogout()}>Logout</button>
                </div>
            </footer>
        </Drawer>
    )
}