import React, { useCallback, useContext, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticateServicePostApiAuthenticateLogin } from '../../openapi/queries';
import { AuthContext, AzureLoginUrl } from '../../contexts/AuthContext';
import { ReactComponent as MSLogo } from '../../assets/Microsoft_logo.svg';
import { ConfigContext } from '../../contexts/ConfigContext';

const Login: React.FC = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { logIn } = useContext(AuthContext);
    const { featureFlags } = useContext(ConfigContext);
    const { mutateAsync, isPending, isError } = useAuthenticateServicePostApiAuthenticateLogin();
    const location = useLocation();
    const navigate = useNavigate();

    const onFinish = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        mutateAsync({ requestBody: { password, userName: username } })
            .then(res => {
                localStorage.setItem('refreshToken', res.refreshToken!);
                localStorage.setItem('accessToken', res.accessToken!);
                logIn();

                const origin = location.state?.from?.pathname || '/create';
                navigate(origin);
            });
    }, [mutateAsync, password, username, logIn, location, navigate]);

    return (
        <>
            <h1>Login to your account</h1>
            <form onSubmit={onFinish}>
                <input type='email' required onChange={e => setUsername(e.target.value)} name='username' placeholder='Email address' />
                <input type='password' required onChange={e => setPassword(e.target.value)} name='password' placeholder='Password' />
                <button type='submit' disabled={isPending}>Sign in</button>
                {isError && (
                    <p className='error'>Wrong username or password</p>
                )}
                <Link to={'/users/forgotpassword'}>Forgot password</Link>
            </form>

            {featureFlags.EntraLogin && 
                <div>
                    <h2 className='divider'>or</h2>
                    <a className='button' href={AzureLoginUrl}><MSLogo />  Sign in with Microsoft</a>
                </div>
            }
        </>
    );
};

export default Login;